(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.regexp.to-string.js";
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
// from http://jasonwatmore.com/post/2017/09/16/react-redux-user-registration-and-login-tutorial-example#alert-actions-js

/*
 * MODIFIED
 *   $Date: Wed Feb 13 16:02:29 PST 2019$
 *
 */

// import { Action } from '../types';
import { userService, history } from '../services';
import { alertActions } from '.';
function login(username, password, human) {
  function request(user) {
    return {
      type: 'AUTH_LOGIN_REQUEST',
      user: user
    };
  }
  function success(user) {
    return {
      type: 'AUTH_LOGIN_SUCCESS',
      user: user
    };
  }
  function failure(error) {
    return {
      type: 'AUTH_LOGIN_FAILURE',
      error: error
    };
  }
  return function (dispatch, getState) {
    dispatch(request({
      username: username
    }));
    userService.login(username, password, human, getState().api).then(function (user) {
      dispatch(success(user));
      // history.push('/');
    }, function (error) {
      dispatch(failure(error.toString()));
      dispatch(alertActions.error(error.toString()));
    });
  };
} // login

function logout(cookies) {
  userService.logout(cookies);
  return {
    type: 'AUTH_LOGOUT'
  };
}
function register(username) {
  function request(user) {
    return {
      type: 'AUTH_REGISTER_REQUEST',
      user: user
    };
  }
  function success(user) {
    return {
      type: 'AUTH_REGISTER_SUCCESS',
      user: user
    };
  }
  function failure(error) {
    return {
      type: 'AUTH_REGISTER_FAILURE',
      error: error
    };
  }
  return function (dispatch, getState) {
    dispatch(request());
    userService.register(username, getState().api).then(function (user) {
      dispatch(success(user));
      history.push('/login');
      dispatch(alertActions.success('Registration successful'));
    }, function (error) {
      dispatch(failure(error.toString()));
      dispatch(alertActions.error(error.toString()));
    });
  };
}
var getUser = function getUser(id, token) {
  function request(id_) {
    return {
      type: 'AUTH_GETUSER_REQUEST',
      user: id_
    };
  }
  function success(user) {
    return {
      type: 'AUTH_GETUSER_SUCCESS',
      user: user
    };
  }
  function failure(error) {
    return {
      type: 'AUTH_GETUSER_FAILURE',
      error: error
    };
  }
  return function (dispatch, getState) {
    dispatch(request(id));
    if (!id) {
      dispatch(failure('no user id supplied'));
    } else {
      userService.getById(id, token, getState().api).then(function (user) {
        return dispatch(success(user));
      }, function (error) {
        return dispatch(failure(error.toString()));
      });
    }
  };
};
function getAll(opts) {
  function request() {
    return {
      type: 'NONAUTH_GETALL_REQUEST'
    };
  }
  function success(users) {
    return {
      type: 'NONAUTH_GETALL_SUCCESS',
      users: users
    };
  }
  function failure(error) {
    return {
      type: 'NONAUTH_GETALL_FAILURE',
      error: error
    };
  }
  return function (dispatch, getState) {
    dispatch(request());
    var token = opts.token;
    if (token === undefined) dispatch(failure('no access token'));else userService.getAll(token, getState().api).then(function (users) {
      return dispatch(success(users));
    }, function (error) {
      return dispatch(failure(error.toString()));
    });
  };
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  function request(id_) {
    return {
      type: 'AUTH_DELETE_REQUEST',
      id_: id_
    };
  }
  function success(id_) {
    return {
      type: 'AUTH_DELETE_SUCCESS',
      id_: id_
    };
  }
  function failure(error) {
    return {
      type: 'AUTH_DELETE_FAILURE',
      error: error
    };
  }
  return function (dispatch, getState) {
    dispatch(request(id));
    userService.delete(getState().api).then(function (id_) {
      return dispatch(success(id_));
    }, function (error) {
      return dispatch(failure(error.toString()));
    });
  };
}
var userActions = {
  login: login,
  logout: logout,
  register: register,
  getAll: getAll,
  getUser: getUser,
  delete: _delete
};
var _default = userActions;
export default _default;
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(login, "login", "/usr/share/vista/model-site/src/actions/auth.actions.js");
  reactHotLoader.register(logout, "logout", "/usr/share/vista/model-site/src/actions/auth.actions.js");
  reactHotLoader.register(register, "register", "/usr/share/vista/model-site/src/actions/auth.actions.js");
  reactHotLoader.register(getUser, "getUser", "/usr/share/vista/model-site/src/actions/auth.actions.js");
  reactHotLoader.register(getAll, "getAll", "/usr/share/vista/model-site/src/actions/auth.actions.js");
  reactHotLoader.register(_delete, "_delete", "/usr/share/vista/model-site/src/actions/auth.actions.js");
  reactHotLoader.register(userActions, "userActions", "/usr/share/vista/model-site/src/actions/auth.actions.js");
  reactHotLoader.register(_default, "default", "/usr/share/vista/model-site/src/actions/auth.actions.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();