import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.string.iterator.js";
import "core-js/modules/es6.array.from.js";
import "core-js/modules/es6.function.name.js";
import _extend from "lodash/extend";
import _constant from "lodash/constant";
(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
import "core-js/modules/es6.array.slice.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.regexp.to-string.js";
import "core-js/modules/es6.regexp.match.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.array.iterator.js";
import "core-js/modules/web.dom.iterable.js";
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
/* eslint no-nested-ternary: warn */
/* eslint camelcase: warn */
/* eslint lodash/preferred-alias: warn */
/*
 * CREATED
 *    Wed Nov 27 15:24:43 PST 2019
 *
 * MODIFIED
 *    $Date: Mon Jan 20 01:11:13 PST 2020$
 *
 */
var API_URL = 'http://localhost:3306/api/catalogs';
var ACTOK = 'wCqCnyuRnHVZ9aph4rPF4iCRbGiS4OICesw9uEzIMvyUodHtXO3KTCNry3J2PKFQ';
var f_ = function f_(s) {
  var l = s[s.length - 1];
  var r = s.slice(0, -1);
  // return r + (parseInt(l, 36) + 1).toString(36).toUpperCase();
  return r + (parseInt(l, 36) + 1).toString(36);
};
var base36 = {
  inc: function inc(s) {
    var l = s[s.length - 1];
    var r = s.slice(0, -1);
    var out = l.match(/^z$/i) ? f_(r || '0') + 0 : f_(s);
    return out;
    // return out.toUpperCase();
  },
  dec: function dec() {
    return _constant(null);
  },
  max: function max(arr) {
    return _typeof(arr) === 'object' ? arr.length > 1 ? _toConsumableArray(arr.sort()).slice(-1)[0] : arr[0] : arr;
  }
};
var logic = function logic(obj) {
  var cb = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  var apiUrl = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : API_URL;
  var accessToken = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : ACTOK;
  var hierarchy = obj.hierarchy,
    num_embryos = obj.num_embryos,
    num_blues = obj.num_blues;

  // create an empty injection:
  var empty_emb = {
    hierarchy: '00000000000000000000',
    is_blue: false,
    note: null,
    // shipping: false,
    embryo: null,
    genotype: null,
    // 'not.tested',
    // is_shipped: false,
    // is_show: false,
    type: 'EMBRYO'
  };
  var actionDiv = document.getElementById('actions');
  var INFO = function INFO(h) {
    console.log("INFO: ".concat(h));
    if (cb !== null) cb("INFO: ".concat(h), actionDiv);
  };
  var WARNING = function WARNING(h) {
    console.log("WARN: ".concat(h, " already exists"));
    if (cb !== null) cb("WARN: ".concat(h, " already exists"), actionDiv);
  };
  var ERROR = function ERROR(h) {
    console.log("ERROR: ".concat(h, " already exists"));
    if (cb !== null) cb("ERROR: ".concat(h, " already exists"), actionDiv);
  };
  var hierarchies = {
    LOCUS: 4,
    ELEMENT: 8,
    EXPERIMENT: 12,
    INJECTION: 16,
    EMBRYO: 20
  };

  // the reverse of 'hierarchies()'
  var seihcrareih = function seihcrareih(h) {
    var H = hierarchies;
    var level = Object.keys(H).filter(function (k) {
      return H[k] === h;
    });
    return level[0];
  };
  var tree;
  var URI = "".concat(apiUrl, "?filter=");
  if (/^.{16}$/.test(hierarchy)) {
    INFO('looking for embryos', actionDiv);
    URI += "{\"where\":{\"hierarchy\":{\"regexp\":\"^".concat(hierarchy, "....$\"}}}");
  } else {
    return [{
      hierarchy: hierarchy,
      assembly_line_position: 'error:hierarchy is wrong'
    }];
  }
  URI += "&access_token=".concat(accessToken);
  var request = fetch(URI).then(function (res) {
    return /* console.log('RESPONSE', res); */res.json();
  }, function (rej) {
    console.log('AJAX REJECTED', rej);
    cb(JSON.stringify({
      AJAX_REJECTED: rej
    }), actionDiv);
  });

  /*
  [
  {
    "created": "2019-11-27T21:46:50.070Z",
    "genotype": "no.test",
    "is_blue": true,
    "hierarchy": "01Z50001000200010000",
    "note": "",
    "serial": "2561.1.2.1.0",
    "type": "EMBRYO",
    "shipping": "",
    "embryo": "0"
  },
  {
    "created": "2019-11-27T20:31:38.146Z",
    "genotype": "no.test",
    "is_blue": true,
    "hierarchy": "01Z50001000200010001",
    "note": "",
    "serial": "2561.1.2.1.1",
    "type": "EMBRYO",
    "shipping": "",
    "embryo": "1"
  },
  ...
  ] */

  tree = request.then(function (result) {
    var embryos = result;
    var embHierarchy = "".concat(hierarchy, "0001"); // of the injection. In the event
    var vacant = embHierarchy; // when no embryos exist for the
    var lenBlues = 0; // injection
    var serial = 1;
    if (embryos.length > 0) {
      // embryos already exist
      embHierarchy = embryos[0].hierarchy;
      var what = seihcrareih(embHierarchy.length);
      WARNING("".concat(what, " ").concat(embHierarchy));
      if (what !== 'EMBRYO') {
        return [{
          hierarchy: embryos[0].hierarchy,
          assembly_line_position: "error:type is ".concat(what, " (should be EMBRYO)")
        }];
      }
      // update the arguments 'vacant', 'lenBlues', etc..
      var from = embHierarchy;
      var to = embryos[embryos.length - 1].hierarchy;
      WARNING("embryos ".concat(from, "...").concat(to, " (").concat(embryos.length, ")")); // embryos already exist
      INFO('update vacant ids for new embryos');
      vacant = base36.inc(base36.max(embryos.map(function (i) {
        return i.hierarchy;
      })));
      INFO('update the next available embryo number');
      serial = embryos.length;
      lenBlues = embryos.reduce(function (s, e) {
        return s + (e.is_blue === true);
      }, 0);
    }
    if (embryos.length <= num_embryos) {
      var N = num_embryos - result.length;
      INFO("backfilling ".concat(N, " records"));
      INFO("first vacant id ".concat(vacant));
      INFO("first embryo number ".concat(serial));
      // generate hierarchies for the N non-existing embryos 
      var vacants = _toConsumableArray(Array(N - 1).keys()).reduce(function (a) {
        return [].concat(_toConsumableArray(a), [base36.inc(a[a.length - 1])]);
      }, [vacant]);
      var newEmbryos = vacants.map(function (vcnt, i) {
        return _extend({}, empty_emb, {
          hierarchy: vcnt,
          embryo: i + serial
        });
      });
      INFO("".concat(lenBlues, " existing blues"));
      var numBluesNeeded = num_blues - lenBlues;
      if (numBluesNeeded > 0) {
        INFO("assigning ".concat(numBluesNeeded, " is_blue tags"));
        if (numBluesNeeded <= N) {
          // assign is_blue:true to numBluesNeeded
          // embryos of the newly created ones
          _toConsumableArray(Array(numBluesNeeded).keys()).forEach(function (k) {
            newEmbryos[k].is_blue = true;
          });
        } else {
          // need to assign is_blue:true to existing values
          if (numBluesNeeded > embryos.length + newEmbryos.length) {
            // error
            ERROR('too many blues requested');
            return [{
              hierarchy: hierarchy,
              assembly_line_position: "error:too many blues requested (".concat(numBluesNeeded, " out of ").concat(embryos.length + newEmbryos.length, ")")
            }];
          }
          var modificationTime = new Date().toISOString();
          _toConsumableArray(Array(numBluesNeeded - N).keys()).forEach(function (k) {
            _extend(embryos[k], {
              method: 'PATCH',
              is_blue: true,
              modified: modificationTime
            });
          });
        }
      }
      return [[].concat(_toConsumableArray(embryos), _toConsumableArray(newEmbryos))];
    }
    // embryos.length > num_embryos
    // embryos already exist, but their number is greater than the
    // requested number of embryos
    console.log('embryos exist and exceed the number requested');
    INFO('embryos exist and exceed the number requested');
    tree = [{
      hierarchy: embryos[0].hierarchy,
      assembly_line_position: 'error:embryos exist and exceed the number requested'
    }];
    return tree;
  });
  return tree;
};
var _default = logic;
export default _default;
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(API_URL, "API_URL", "/usr/share/vista/model-site/src/utils/newEmbryo_logic.js");
  reactHotLoader.register(ACTOK, "ACTOK", "/usr/share/vista/model-site/src/utils/newEmbryo_logic.js");
  reactHotLoader.register(f_, "f_", "/usr/share/vista/model-site/src/utils/newEmbryo_logic.js");
  reactHotLoader.register(base36, "base36", "/usr/share/vista/model-site/src/utils/newEmbryo_logic.js");
  reactHotLoader.register(logic, "logic", "/usr/share/vista/model-site/src/utils/newEmbryo_logic.js");
  reactHotLoader.register(_default, "default", "/usr/share/vista/model-site/src/utils/newEmbryo_logic.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();