(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
import "core-js/modules/es6.array.find.js";
import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.array.slice.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import "core-js/modules/es6.string.iterator.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.array.iterator.js";
import "core-js/modules/web.dom.iterable.js";
import "core-js/modules/es6.array.from.js";
import "core-js/modules/es6.function.name.js";
import "core-js/modules/es6.number.constructor.js";
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
/* eslint no-param-reassign: warn */
/*
 * MODIFIED
 *    $Date: $
 */
import { invariant } from './invariant';
import reorder from './reorder';
var withNewTaskIds = function withNewTaskIds(column, taskIds) {
  return {
    id: column.id,
    title: column.title,
    taskIds: taskIds
  };
};
var reorderSingleDrag = function reorderSingleDrag(_ref) {
  var entities = _ref.entities,
    selectedTaskIds = _ref.selectedTaskIds,
    source = _ref.source,
    destination = _ref.destination;
  // moving in the same list
  if (source.droppableId === destination.droppableId) {
    var column = entities.columns[source.droppableId];
    var reordered = reorder(column.taskIds, source.index, destination.index);
    var _updated = _objectSpread(_objectSpread({}, entities), {}, {
      columns: _objectSpread(_objectSpread({}, entities.columns), {}, _defineProperty({}, column.id, withNewTaskIds(column, reordered)))
    });
    return {
      entities: _updated,
      selectedTaskIds: selectedTaskIds
    };
  }

  // moving to a new list
  var home = entities.columns[source.droppableId];
  var foreign = entities.columns[destination.droppableId];

  // the id of the task to be moved
  var taskId = home.taskIds[source.index];

  // remove from home column
  var newHomeTaskIds = _toConsumableArray(home.taskIds);
  newHomeTaskIds.splice(source.index, 1);

  // add to foreign column
  var newForeignTaskIds = _toConsumableArray(foreign.taskIds);
  newForeignTaskIds.splice(destination.index, 0, taskId);
  var updated = _objectSpread(_objectSpread({}, entities), {}, {
    columns: _objectSpread(_objectSpread({}, entities.columns), {}, _defineProperty(_defineProperty({}, home.id, withNewTaskIds(home, newHomeTaskIds)), foreign.id, withNewTaskIds(foreign, newForeignTaskIds)))
  });
  return {
    entities: updated,
    selectedTaskIds: selectedTaskIds
  };
};
export var getHomeColumn = function getHomeColumn(entities, taskId) {
  var columnId = entities.columnOrder.find(function (id) {
    var column = entities.columns[id];
    return column.taskIds.includes(taskId);
  });
  invariant(columnId, 'Count not find column for task');
  return entities.columns[columnId];
};
var reorderMultiDrag = function reorderMultiDrag(_ref2) {
  var entities = _ref2.entities,
    selectedTaskIds = _ref2.selectedTaskIds,
    source = _ref2.source,
    destination = _ref2.destination;
  var start = entities.columns[source.droppableId];
  var dragged = start.taskIds[source.index];
  var insertAtIndex = function () {
    var destinationIndexOffset = selectedTaskIds.reduce(function (previous, current) {
      if (current === dragged) {
        return previous;
      }
      var final = entities.columns[destination.droppableId];
      var column = getHomeColumn(entities, current);
      if (column !== final) {
        return previous;
      }
      var index = column.taskIds.indexOf(current);
      if (index >= destination.index) {
        return previous;
      }

      // the selected item is before the destination index
      // we need to account for this when inserting into the new location
      return previous + 1;
    }, 0);
    var result = destination.index - destinationIndexOffset;
    return result;
  }();

  // doing the ordering now as we are required to look up columns
  // and know original ordering
  var orderedSelectedTaskIds = _toConsumableArray(selectedTaskIds);
  orderedSelectedTaskIds.sort(function (a, b) {
    // moving the dragged item to the top of the list
    if (a === dragged) {
      return -1;
    }
    if (b === dragged) {
      return 1;
    }

    // sorting by their natural indexes
    var columnForA = getHomeColumn(entities, a);
    var indexOfA = columnForA.taskIds.indexOf(a);
    var columnForB = getHomeColumn(entities, b);
    var indexOfB = columnForB.taskIds.indexOf(b);
    if (indexOfA !== indexOfB) {
      return indexOfA - indexOfB;
    }

    // sorting by their order in the selectedTaskIds list
    return -1;
  });

  // we need to remove all of the selected tasks from their columns
  var withRemovedTasks = entities.columnOrder.reduce(function (previous, columnId) {
    var column = entities.columns[columnId];

    // remove the id's of the items that are selected
    var remainingTaskIds = column.taskIds.filter(function (id) {
      return !selectedTaskIds.includes(id);
    });
    previous[column.id] = withNewTaskIds(column, remainingTaskIds);
    return previous;
  }, entities.columns);
  var final = withRemovedTasks[destination.droppableId];
  var withInserted = function () {
    var base = _toConsumableArray(final.taskIds);
    base.splice.apply(base, [insertAtIndex, 0].concat(_toConsumableArray(orderedSelectedTaskIds)));
    return base;
  }();

  // insert all selected tasks into final column
  var withAddedTasks = _objectSpread(_objectSpread({}, withRemovedTasks), {}, _defineProperty({}, final.id, withNewTaskIds(final, withInserted)));
  var updated = _objectSpread(_objectSpread({}, entities), {}, {
    columns: withAddedTasks
  });
  return {
    entities: updated,
    selectedTaskIds: orderedSelectedTaskIds
  };
};
export var mutliDragAwareReorder = function mutliDragAwareReorder(args) {
  if (args.selectedTaskIds.length > 1) {
    return reorderMultiDrag(args);
  }
  return reorderSingleDrag(args);
};
export var multiSelectTo = function multiSelectTo(entities, selectedTaskIds, newTaskId) {
  // Nothing already selected
  if (!selectedTaskIds.length) {
    return [newTaskId];
  }
  var columnOfNew = getHomeColumn(entities, newTaskId);
  var indexOfNew = columnOfNew.taskIds.indexOf(newTaskId);
  var lastSelected = selectedTaskIds[selectedTaskIds.length - 1];
  var columnOfLast = getHomeColumn(entities, lastSelected);
  var indexOfLast = columnOfLast.taskIds.indexOf(lastSelected);

  // multi selecting to another column
  // select everything up to the index of the current item
  if (columnOfNew !== columnOfLast) {
    return columnOfNew.taskIds.slice(0, indexOfNew + 1);
  }

  // multi selecting in the same column
  // need to select everything between the last index and the current index inclusive

  // nothing to do here
  if (indexOfNew === indexOfLast) {
    return null;
  }
  var isSelectingForwards = indexOfNew > indexOfLast;
  var start = isSelectingForwards ? indexOfLast : indexOfNew;
  var end = isSelectingForwards ? indexOfNew : indexOfLast;
  var inBetween = columnOfNew.taskIds.slice(start, end + 1);

  // everything inbetween needs to have it's selection toggled.
  // with the exception of the start and end values which will always be selected

  var toAdd = inBetween.filter(function (taskId) {
    // if already selected: then no need to select it again
    if (selectedTaskIds.includes(taskId)) {
      return false;
    }
    return true;
  });
  var sorted = isSelectingForwards ? toAdd : _toConsumableArray(toAdd).reverse();
  var combined = [].concat(_toConsumableArray(selectedTaskIds), _toConsumableArray(sorted));
  return combined;
};
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(withNewTaskIds, "withNewTaskIds", "/usr/share/vista/model-site/src/components/MultiDragQueueTable/utils.js");
  reactHotLoader.register(reorderSingleDrag, "reorderSingleDrag", "/usr/share/vista/model-site/src/components/MultiDragQueueTable/utils.js");
  reactHotLoader.register(getHomeColumn, "getHomeColumn", "/usr/share/vista/model-site/src/components/MultiDragQueueTable/utils.js");
  reactHotLoader.register(reorderMultiDrag, "reorderMultiDrag", "/usr/share/vista/model-site/src/components/MultiDragQueueTable/utils.js");
  reactHotLoader.register(mutliDragAwareReorder, "mutliDragAwareReorder", "/usr/share/vista/model-site/src/components/MultiDragQueueTable/utils.js");
  reactHotLoader.register(multiSelectTo, "multiSelectTo", "/usr/share/vista/model-site/src/components/MultiDragQueueTable/utils.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();