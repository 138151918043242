import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.string.iterator.js";
import "core-js/modules/es6.array.from.js";
import "core-js/modules/es6.function.name.js";
import _extend from "lodash/extend";
import _constant from "lodash/constant";
import _isNil from "lodash/isNil";
(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.array.iterator.js";
import "core-js/modules/web.dom.iterable.js";
import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.array.slice.js";
import "core-js/modules/es6.regexp.to-string.js";
import "core-js/modules/es6.regexp.match.js";
import "core-js/modules/es6.string.repeat.js";
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
/* eslint import/prefer-default-export: warn */
/* eslint no-unused-vars: warn */
/* eslint no-eval: warn */
/* eslint camelcase: warn */
/* eslint lodash/preferred-alias: warn */
/* eslint react/no-this-in-sfc: warn */
/*
 * MODIFIED
 *    $Date: Sun Mar  1 00:17:12 IST 2020$
 */
/* scoping hack: functions cannot be easily eval'ed after being
 * imported from another file. We therefore place them inside the object AA
 * Read https://stackoverflow.com/a/58116257/2821963 for the reason
 */
import { parseBool, parseUCSCcoords, parseATGC, parseString, parseChar, parseStaging, parseDate, parseInteger, parseNumber, parseGenotype, neq } from './formatParsers';
import { empty_inj } from './newEntry_logic';
var AA = {
  parseString: parseString,
  parseBool: parseBool,
  parseUCSCcoords: parseUCSCcoords,
  parseATGC: parseATGC,
  parseChar: parseChar,
  parseStaging: parseStaging,
  parseDate: parseDate,
  parseInteger: parseInteger,
  parseNumber: parseNumber,
  parseGenotype: parseGenotype
};
var LETTERS = _toConsumableArray('ABCDEFGHIJKLMNOPQRSTUVWXYZ');
export var generateGrid = function generateGrid(i, hdr) {
  var dataObj = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
  var writeEnableColumns = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];
  var j = hdr.length;
  var f = function f(a, b) {
    var _ref;
    return (_ref = []).concat.apply(_ref, _toConsumableArray(a.map(function (d) {
      return b.map(function (e) {
        return [].concat(d, e);
      });
    })));
  };
  var cartesian = function cartesian(a, b) {
    for (var _len = arguments.length, c = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
      c[_key - 2] = arguments[_key];
    }
    return b ? cartesian.apply(void 0, [f(a, b)].concat(c)) : a;
  };
  var a = _toConsumableArray(Array(i).keys());
  var b = _toConsumableArray(Array(j).keys());
  console.log('ij', a, j);
  var accessors = hdr.map(function (h) {
    return h.accessor ? h.accessor : h.value;
  });
  var Cprod = cartesian(a, b).map(function (ij) {
    return {
      key: LETTERS[ij[1]] + ij[0],
      row: ij[0],
      col: ij[1],
      value: dataObj ? dataObj[ij[0]][accessors[ij[1]]] : 'A',
      className: hdr[ij[1]].className ? hdr[ij[1]].className : null,
      style: hdr[ij[1]].style ? hdr[ij[1]].style : null,
      component: hdr[ij[1]].component ? hdr[ij[1]].component(ij[0]) : null,
      // key: hdr[ij[1]].component ? ij[0] : null,
      forceComponent: !!hdr[ij[1]].component,
      format: hdr[ij[1]].format,
      readOnly: !writeEnableColumns.includes(accessors[ij[1]]),
      dataEditor: hdr[ij[1]].dataEditor /* ij[0]+ij[1] */
    };
  });
  var C = [];
  while (Cprod.length) C.push(Cprod.splice(0, j));
  return C;
};

// false if the two arrays are identical
export var cmp = function cmp(Arr1, Arr2) {
  if (Arr1.length !== Arr2.length) return false;
  var i = 0;
  while (Arr1[i] === Arr2[i] && i < Arr1.length) {
    i += 1;
  }
  return i < Arr1.length;
};
export var processMenuOptions = function processMenuOptions(props) {
  var cmo = props.contextMenuOptions,
    dupRow = props.dupRow,
    delRow = props.delRow;
  var contextMenuOptions = {
    dupRow: dupRow,
    delRow: delRow
  };
  if (cmo) {
    var cmds = Object.keys(cmo);
    cmds.forEach(function (cmd) {
      // is it an Array? break it down and translate a possible text command
      var cmd1 = cmo[cmd];
      if (Array.isArray(cmd1)) {
        cmd1 = cmd1.map(function (c) {
          var cc = c;
          if (typeof c === 'string') {
            try {
              cc = eval(c);
            } catch (e) {
              cc = function cc() {};
            }
          }
          return cc;
        });
      }
      cmo[cmd] = cmd1;
    });
    contextMenuOptions = cmo;
  }
  return contextMenuOptions;
};

// key is the accessor
// value is the quantity of the cell
// headers is the array of objects that describe the formatting, width, etc of the column
export var isCellValid = function isCellValid(key, value, headers) {
  var syntaxOnly = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;
  var verbose = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : true;
  if (headers.length === 0) return false; // it's an error elegantly ignored
  var _headers$filter = headers.filter(function (h) {
      return h.accessor ? h.accessor === key : h.value === key;
    }),
    _headers$filter2 = _slicedToArray(_headers$filter, 1),
    header = _headers$filter2[0];
  if (!header) return true; // ignore silently a cell that is not in the visual
  var format = header.format,
    excludeFromChecks = header.excludeFromChecks,
    component = header.component,
    fillValue = header.fillValue;
  var validated = false;
  if (header && excludeFromChecks) return true;
  if (component) return true;
  if (typeof format === 'undefined') return false;
  if (format === '') {
    validated = typeof value === 'string' && !/^$/.test(value);
  } else {
    var fn = eval("AA.parse".concat(format)); // parseNumber, parseBool, ...
    validated = fn(value);
  }
  if (!syntaxOnly && validated) {
    // check also the value itself against the fill value
    if (header) {
      var _HDR = fillValue;
      validated = _isNil(_HDR) ? true : neq(value, _HDR);
    } else validated = false;
  }

  /*console.log(
    '----------V:', 
    key,
    value,
    format,
    validated,
    typeof value
  );*/

  if (verbose) console.log('expandable VALIDATOR: ', key, value, format, validated, _typeof(value));
  return validated;
};
var f_ = function f_(s) {
  var l = s[s.length - 1];
  var r = s.slice(0, -1);
  return r + (parseInt(l, 36) + 1).toString(36);
};
export var base36 = {
  inc: function inc(s) {
    var l = s[s.length - 1];
    var r = s.slice(0, -1);
    var out = l.match(/^z$/i) ? f_(r || '0') + 0 : f_(s);
    // return out.toUpperCase();
    return out;
  },
  dec: function dec() {
    return _constant(null);
  },
  max: function max(arr) {
    return _toConsumableArray(arr.sort()).slice(-1)[0];
  }
};

// get the pattern for sibling injection of the current injection record
export var getInjPattern = function getInjPattern(obj) {
  // [a] break down the hierarchy
  var splt = obj.hierarchy.match(/.{1,4}/g);
  var parents = splt.reduce(function (lines, s) {
    lines.push(lines[lines.length - 1] + s);
    return lines;
  }, ['']).slice(1) // remove the first empty element
  .filter(function (h) {
    return h.length <= 12;
  });
  // ["001Z", "001Z0001", "001Z00010001"]
  // The last item "001Z000100010001" is left out

  var queryParents = parents.map(function (it) {
    return "\"".concat(it, "\"");
  }); // quote them
  // ['"001Z"', '"001Z0001"', '"001Z00010001"']

  // [c] find a vacant hierarchy number in the table (remote database search)
  // TODO: check the snapshot database as well
  // const s = obj.hierarchy; // hierarchy of the original injection
  var _parents$slice = parents.slice(-1),
    _parents$slice2 = _slicedToArray(_parents$slice, 1),
    parent = _parents$slice2[0];
  var pattern = "^".concat(parent).concat('.'.repeat(4), "$"); // prepare a pattern for sibling search

  return pattern;
};

// return the workflow after querying the relevant injections for sliding a reinjection in
export var processInj = function processInj(injections) {
  var obj = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var accessToken = arguments.length > 2 ? arguments[2] : undefined;
  var apiUrl = arguments.length > 3 ? arguments[3] : undefined;
  var thisInjs = injections
  // the following filter checks if there is an injection in queue
  // for this experiment. If true, it returns the queued
  // injection with an error tag
  .filter(function (inj) {
    return inj.transfer_date === '0000-01-01T00:00:00.000Z' || inj.RT_id === null || inj.RT_id === '';
  });
  var thisInj;
  if (thisInjs.length) {
    // an injection already exists in the queue. dispatch an error
    // console.log(thisInj)
    var _thisInjs = _slicedToArray(thisInjs, 1);
    thisInj = _thisInjs[0];
    return {
      workflow: [{
        error: 'error:injection exists',
        data: _extend({}, thisInj, {
          assembly_line_position: 'error:injection exists'
        })
      }]
    };
  }
  var vacant = base36.inc(base36.max(injections.map(function (I) {
    return I.hierarchy;
  })));
  var parent = vacant.slice(0, -4);
  var workflow = {
    workflow: [{
      url: "".concat(apiUrl, "/catalogs/").concat(parent, "?access_token=").concat(accessToken()),
      method: 'PATCH',
      data: {
        hierarchy: parent,
        status: 'in.progress'
      }
    }, {
      url: "".concat(apiUrl, "/catalogs/?access_token=").concat(accessToken()),
      data: _extend({}, empty_inj, {
        hierarchy: vacant
      }, obj)
    }]
  };
  return workflow;
};
export var getClosest = function getClosest(elem_, selector) {
  var elem = elem_;
  // Element.matches() polyfill
  if (!Element.prototype.matches) {
    Element.prototype.matches = Element.prototype.matchesSelector || Element.prototype.mozMatchesSelector || Element.prototype.msMatchesSelector || Element.prototype.oMatchesSelector || Element.prototype.webkitMatchesSelector || function (s) {
      var matches = (this.document || this.ownerDocument).querySelectorAll(s);
      var i = matches.length - 1;
      while (i >= 0 && matches.item(i) !== this) i -= 1;
      return i > -1;
    };
  }

  // Get the closest matching element
  for (; elem && elem !== document; elem = elem.parentNode) {
    if (elem.matches(selector)) return elem;
  }
  return null;
};
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(AA, "AA", "/usr/share/vista/model-site/src/utils/DataSheetTools.js");
  reactHotLoader.register(LETTERS, "LETTERS", "/usr/share/vista/model-site/src/utils/DataSheetTools.js");
  reactHotLoader.register(generateGrid, "generateGrid", "/usr/share/vista/model-site/src/utils/DataSheetTools.js");
  reactHotLoader.register(cmp, "cmp", "/usr/share/vista/model-site/src/utils/DataSheetTools.js");
  reactHotLoader.register(processMenuOptions, "processMenuOptions", "/usr/share/vista/model-site/src/utils/DataSheetTools.js");
  reactHotLoader.register(isCellValid, "isCellValid", "/usr/share/vista/model-site/src/utils/DataSheetTools.js");
  reactHotLoader.register(f_, "f_", "/usr/share/vista/model-site/src/utils/DataSheetTools.js");
  reactHotLoader.register(base36, "base36", "/usr/share/vista/model-site/src/utils/DataSheetTools.js");
  reactHotLoader.register(getInjPattern, "getInjPattern", "/usr/share/vista/model-site/src/utils/DataSheetTools.js");
  reactHotLoader.register(processInj, "processInj", "/usr/share/vista/model-site/src/utils/DataSheetTools.js");
  reactHotLoader.register(getClosest, "getClosest", "/usr/share/vista/model-site/src/utils/DataSheetTools.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();