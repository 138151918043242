import _assign from "lodash/assign";
(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
/*
 *
 * WARNING: THIS IS A COMPLETE HACK
 *   We need a way to pass the API url from the server to the client.
 *   The current infrastructure takes configuration info from a
 *   file named config/default.js. The data there is processed on
 *   the server and the client separately, so the environment
 *   variables from package.json, that target the client, cannot
 *   be set there because the client reads empty sets. So, we have
 *   hacked the store's initial state so that the API url's are
 *   passed into window.__INITIAL_STATE__ (in utils/renderHtml)
 *   using the tag 'api', and when the store is created on the
 *   client side, we use this reducer to announce to redux that it
 *   should expect a reducer named 'api'. This file is a mock up
 *   since there are no actions associated with the 'api' reducer.
 *
 *   Adapted from Genetics/vistav3-curation-site
 *
 * CREATED
 *   Wed Jan 30 20:04:44 PST 2019
 *
 * MODIFIED
 *   $Date: $
 *
 */
var initialState = {
  readyStatus: 'API_SUCCESS',
  err: null,
  list: []
};
var _default = function _default() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case 'API_REQUESTING':
      return _assign({}, state, {
        readyStatus: 'API_REQUESTING'
      });
    case 'API_FAILURE':
      return _assign({}, state, {
        readyStatus: 'API_FAILURE',
        err: action.err
      });
    case 'API_SUCCESS':
      return _assign({}, state, {
        readyStatus: 'API_SUCCESS',
        list: [action.data]
      });
    default:
      return state;
  }
};
export default _default;
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(initialState, "initialState", "/usr/share/vista/model-site/src/reducers/api.js");
  reactHotLoader.register(_default, "default", "/usr/share/vista/model-site/src/reducers/api.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();