function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.string.iterator.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.array.iterator.js";
import "core-js/modules/web.dom.iterable.js";
import "core-js/modules/es6.array.from.js";
import "core-js/modules/es6.array.slice.js";
import "core-js/modules/es6.function.name.js";
import "core-js/modules/es6.number.constructor.js";
import _isNil from "lodash/isNil";
import _omit from "lodash/omit";
import _assign from "lodash/assign";
import _extend from "lodash/extend";
(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.regexp.constructor.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.keys.js";
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
/* eslint lodash/preferred-alias: warn */
/*
 * MODIFIED
 *    $Date: Wed Feb 26 18:59:20 IST 2020$
 */
import { genotype } from '../../utils/formatParsers';

// ----------------------------------------------------------------------
// initial values for the class summary
export var initial = _extend.apply(void 0, [{}, {
  null: 0,
  is_blue: 0,
  Num_transgenic: 0
}].concat(_toConsumableArray(genotype.map(function (g) {
  return _defineProperty({}, g.value, 0);
}))));

// ----------------------------------------------------------------------
// create a class summary:
// num_null, num_no.test, num_tandem, num_single, num_random, num_negative,
export var calcSummaries = function calcSummaries(data) {
  console.log("calculat summart:", data);
  var hist = initial;
  hist = data.reduce(function (a, r) {
    var rObj = {};
    if (r.genotype && (r.genotype === "tandem" || r.genotype === "single" || r.genotype === "random")) {
      rObj = _defineProperty(_defineProperty({
        is_blue: a.is_blue + +r.is_blue
      }, r.genotype, a[r.genotype] + 1), "Num_transgenic", a.Num_transgenic + (r.is_blue || /tandem|single|random/.test(r.genotype)));
    } else {
      //in case genotype invalid
      rObj = {
        is_blue: a.is_blue + +r.is_blue,
        Num_transgenic: a.Num_transgenic + (r.is_blue || /tandem|single|random/.test(r.genotype))
      };
    }
    return _assign({}, a, _defineProperty(_defineProperty({
      is_blue: a.is_blue + +r.is_blue
    }, r.genotype, a[r.genotype] + 1), "Num_transgenic", a.Num_transgenic + (r.is_blue || /tandem|single|random/.test(r.genotype))));
  }, initial);
  return _omit(hist, ['', 'undefined']);
};
export var calcSummaries_ = function calcSummaries_(data, cols) {
  var hist = initial;
  // there are cases when columns_ does not contain 'Genotype' as column specification. This is when the histogram is already in place. We take it (no need to reduce)
  var _cols = _slicedToArray(cols, 2),
    col_ = _cols[0],
    col2_ = _cols[1];
  var isCol = col_ !== undefined && 'accessor' in col_; // col exists
  var isCol2 = col2_ !== undefined && 'accessor' in col2_; // col2 exists
  if (isCol) {
    hist = data.reduce(function (a, r) {
      var rcol2 =
      // r[col2] is numeric (or can be converted to such)
      isCol2 && !_isNil(r[col2_.accessor]) && r[col2_.accessor] > 0;
      var colname = rcol2 ? col2_.accessor : r[col_.accessor];
      var colval = rcol2 ? +r[col2_.accessor] : 1;
      var histval = rcol2 ? a[col2_.accessor] : a[r[col_.accessor]];
      return _assign({}, a, _defineProperty({}, colname, histval + colval));
      /*
      const colval = rcol2 ? +r[colname] : 1;
      const histval = rcol2 ? a[colname] : a[r[colname]];
      return _.assign({}, a, {
        [colname === '' ? 'null' : colname]: (histval || a.null) + colval
      }); */
    }, initial);
  }
  return _omit(hist, '');
};
var irrelevant = ['no.test', 'null', 'negative'];
var pattern = new RegExp("(".concat(irrelevant.join('|'), ")"));

// get the relevant keys from the histogram object
export var getHistKeys = function getHistKeys(hist) {
  return Object.keys(hist).filter(function (k) {
    return !pattern.test(k);
  });
};
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(initial, "initial", "/usr/share/vista/model-site/src/components/ExpandableTable/util.js");
  reactHotLoader.register(calcSummaries, "calcSummaries", "/usr/share/vista/model-site/src/components/ExpandableTable/util.js");
  reactHotLoader.register(calcSummaries_, "calcSummaries_", "/usr/share/vista/model-site/src/components/ExpandableTable/util.js");
  reactHotLoader.register(irrelevant, "irrelevant", "/usr/share/vista/model-site/src/components/ExpandableTable/util.js");
  reactHotLoader.register(pattern, "pattern", "/usr/share/vista/model-site/src/components/ExpandableTable/util.js");
  reactHotLoader.register(getHistKeys, "getHistKeys", "/usr/share/vista/model-site/src/components/ExpandableTable/util.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();