(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
var PI = Math.PI;
export function degreesToRadians(degrees) {
  return degrees * PI / 180;
}
export function evaluateViewBoxSize(ratio, baseSize) {
  // Wide ratio
  if (ratio > 1) {
    return "0 0 ".concat(baseSize, " ").concat(baseSize / ratio);
  }
  // Narrow/squared ratio
  return "0 0 ".concat(baseSize * ratio, " ").concat(baseSize);
}
export function evaluateLabelTextAnchor() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    labelPosition = _ref.labelPosition,
    lineWidth = _ref.lineWidth,
    labelHorizontalShift = _ref.labelHorizontalShift;
  // Label in the vertical center
  if (labelHorizontalShift === 0) {
    return 'middle';
  }
  // Outward label
  if (labelPosition > 100) {
    return labelHorizontalShift > 0 ? 'start' : 'end';
  }
  // Inward label
  var innerRadius = 100 - lineWidth;
  if (labelPosition < innerRadius) {
    return labelHorizontalShift > 0 ? 'end' : 'start';
  }
  // Overlying label
  return 'middle';
}
export function valueBetween(value, min, max) {
  if (value > max) return max;
  if (value < min) return min;
  return value;
}
export function extractPercentage(value, percentage) {
  return value * percentage / 100;
}
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(PI, "PI", "/usr/share/vista/model-site/src/components/PieChart/utils.js");
  reactHotLoader.register(degreesToRadians, "degreesToRadians", "/usr/share/vista/model-site/src/components/PieChart/utils.js");
  reactHotLoader.register(evaluateViewBoxSize, "evaluateViewBoxSize", "/usr/share/vista/model-site/src/components/PieChart/utils.js");
  reactHotLoader.register(evaluateLabelTextAnchor, "evaluateLabelTextAnchor", "/usr/share/vista/model-site/src/components/PieChart/utils.js");
  reactHotLoader.register(valueBetween, "valueBetween", "/usr/share/vista/model-site/src/components/PieChart/utils.js");
  reactHotLoader.register(extractPercentage, "extractPercentage", "/usr/share/vista/model-site/src/components/PieChart/utils.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();