import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.string.iterator.js";
import "core-js/modules/es6.array.from.js";
import "core-js/modules/es6.array.slice.js";
import "core-js/modules/es6.function.name.js";
import _isNil from "lodash/isNil";
(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.array.iterator.js";
import "core-js/modules/web.dom.iterable.js";
import "core-js/modules/es6.regexp.match.js";
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
/* eslint camelcase: warn */
/*
 * MODIFIED
 *    $Date: Sun Mar  1 00:43:40 IST 2020$
 */
// recursively traverse up from a HTML node until a parent is
// found with 'split' in its class name
var getIdSplit = function getIdSplit(node) {
  if (_isNil(node)) return null;
  var class_ = node.className;
  if (/split/.test(class_)) {
    return node.id;
  }
  return getIdSplit(node.parentNode);
};

//
// click handler for gutter displacement exposing the pane of
// interest
//
var onSplitClick = function onSplitClick(event) {
  var target = event.target;
  var id = getIdSplit(target);
  if (id === null) return;
  var d = document.getElementsByClassName('split');
  var thisSplit = document.getElementById(id);
  if (thisSplit.getAttribute('data-expanded') === 'true') return;
  var oldSizes = _toConsumableArray(Array(d.length).keys()).map(function (i) {
    return {
      style: d[i].style['flex-basis'],
      percent: d[i].style['flex-basis'].match('calc.([0-9.]+)%')[1],
      newPercent: d[i].id === id ? 80 : 20,
      newStyle: "calc(".concat(d[i].id === id ? 80 : 20, "% - 5px)"),
      expanded: d[i].id === id
    };
  });
  _toConsumableArray(Array(d.length).keys()).forEach(function (i) {
    // temporarily disable all css transitions
    var elementTransition = d[i].style.transition;
    d[i].style.transition = '';
    // on the next frame (as soon as the previous style change has taken effect),
    // explicitly set the element's height to its current pixel height, so we
    // aren't transitioning out of 'auto'
    requestAnimationFrame(function () {
      d[i].style['flex-basis'] = oldSizes[i].style;
      d[i].style.transition = elementTransition;

      // on the next frame (as soon as the previous style change has taken effect),
      // have the element transition to height: 0
      requestAnimationFrame(function () {
        d[i].style['flex-basis'] = oldSizes[i].newStyle;
      });
    });
    d[i].setAttribute('data-expanded', oldSizes[i].expanded);
  });
};
var _default = onSplitClick;
export default _default;
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(getIdSplit, "getIdSplit", "/usr/share/vista/model-site/src/components/experimental-dashboard/handlers.js");
  reactHotLoader.register(onSplitClick, "onSplitClick", "/usr/share/vista/model-site/src/components/experimental-dashboard/handlers.js");
  reactHotLoader.register(_default, "default", "/usr/share/vista/model-site/src/components/experimental-dashboard/handlers.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();