(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
import "core-js/modules/es6.object.freeze.js";
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
/* eslint-disable no-use-before-define */
/* eslint-disable import/prefer-default-export */
export var Status = Object.freeze({
  ERROR: -1,
  WARNING: 2,
  NEUTRAL: 3,
  OK: 1
});

// Reducers

// State
// eslint-disable-line no-undef
// eslint-disable-line no-undef

// Action

// Store
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(Status, "Status", "/usr/share/vista/model-site/src/types/index.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();