var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
module.exports = {
  toolsapihost: process.env.TOOLSAPI_HOST || process.env.NODE_HOST || 'localhost',
  // Define your host from 'package.json'
  toolsapiport: process.env.TOOLSAPI_PORT || 5000,
  toolsapischeme: process.env.RESTAPI_TRANSPORT_SCHEME || 'http',
  restapihost: process.env.RESTAPI_HOST || process.env.NODE_HOST || 'localhost',
  // Define your host from 'package.json'
  restapiport: process.env.RESTAPI_PORT || 3306,
  restapischeme: process.env.RESTAPI_TRANSPORT_SCHEME || 'http',
  curationrestapihost: process.env.CURATION_RESTAPI_HOST || process.env.NODE_HOST || 'localhost',
  // Define your host from 'package.json'
  curationrestapiport: process.env.CURATION_RESTAPI_PORT || 3307,
  host: process.env.NODE_HOST || 'localhost',
  // Define your host from 'package.json'
  port: process.env.PORT,
  app: {
    htmlAttributes: {
      lang: 'en'
    },
    title: 'Vista pre-browser',
    titleTemplate: 'Vista pb - %s',
    meta: [{
      name: 'description',
      content: 'starter for vista offline (internal) site.'
    }]
  }
};