function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
import _isNil from "lodash/isNil";
(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import "core-js/modules/es6.object.assign.js";
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.regexp.split.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.promise.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.number.constructor.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import "core-js/modules/es6.string.iterator.js";
import "core-js/modules/es6.array.iterator.js";
import "core-js/modules/web.dom.iterable.js";
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
/*
 * MODIFIED
 *   $Date: Thu Feb 28 10:21:52 PST 2019$
 *
 */

import fetch from 'fetch-everywhere';
import authHeader from './auth-header';
function _extends() {
  for (var _len = arguments.length, rest = new Array(_len), _key = 0; _key < _len; _key++) {
    rest[_key] = arguments[_key];
  }
  var __extends = Object.assign || function () {
    var target = [];
    var _loop = function _loop() {
      var source = rest[i];
      target.push(Object.keys(source).map(function (key) /* for (const key in source) */{
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          return {
            source: source,
            key: key
          }; // target[key] = source[key];
        }
        return null;
      }));
    };
    for (var i = 1; i < rest.length; i += 1) {
      _loop();
    }
    return target;
  };
  return __extends.apply(this, rest);
}
if (typeof window === 'undefined' || !window) {
  require('localstorage-polyfill'); // placeholder for localStorage
}
function logout(cookies) {
  // remove user from local storage to log user out
  if (!_isNil(cookies)) {
    var opts_ = {
      expires: 'Thu, 01 Jan 1970 00:00:00 GMT'
    };
    var pArr = ['/', '/viewExperiments', '/viewInjections', '/addExperiment'];
    pArr.forEach(function (p) {
      var opts = Object.assign({}, opts_, p);
      cookies.remove('user', opts);
      cookies.remove('userId', opts);
      cookies.remove('id', opts);
      cookies.remove('token', opts);
    });
  } else {
    var cookies_ = document.cookie.split(';');
    for (var i = 0; i < cookies_.length; i += 1) {
      var cookie = cookies_[i];
      var eqPos = cookie.indexOf('=');
      var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      /*
        remove the cookie from all the routes. This is legacy,
         until all clients have the new cookie scheme (available
         on all pages: see UserAuth/Login.js)
       */
      document.cookie = "".concat(name, "=deleted; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT");
      document.cookie = "".concat(name, "=deleted; path=/ExperimentInfo; expires=Thu, 01 Jan 1970 00:00:00 GMT");
      document.cookie = "".concat(name, "=deleted; path=/Tables; expires=Thu, 01 Jan 1970 00:00:00 GMT");
    }
  }
  localStorage.removeItem('user');
}
function handleResponse(response) {
  return response.text().then(function (text) {
    var data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        console.log('LOGGING OUT');
        logout();
        if (typeof window === 'undefined' || !window) window.location.reload(true);
      }
      var error = data && data.message || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}
function login(username, password, human, api) {
  var requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      username: username,
      password: password,
      human: human
    })
  };

  // return axios.get(`${config.apiUrl}/Users/login`, requestOptions)
  return fetch("".concat(api.apiUrl, "/Users/login"), requestOptions).then(handleResponse).then(function (user) {
    // store user details and jwt token in local storage to keep user logged in between page refreshes
    localStorage.setItem('user', JSON.stringify(user));
    var user_ = user;
    if (!('username' in user_)) {
      user_ = _extends({}, user_, {
        username: username
      });
    }
    return user_;
  });
}
function getAll(token, api) {
  var requestOptions = {
    method: 'GET',
    headers: authHeader()
  };
  return fetch("".concat(api.apiUrl, "/users?access_token=").concat(token), requestOptions).then(handleResponse);
}
function getById(id, token, api) {
  var requestOptions = {
    method: 'GET',
    headers: authHeader(token)
  };
  var filter = "filter=".concat(JSON.stringify({
    include: 'roles'
  }));
  var searchParams = "".concat(api.apiUrl, "/users/").concat(id, "?access_token=").concat(token, "&").concat(filter);
  console.log('fetching in getById');
  console.log(searchParams);
  // return axios.get(searchParams, requestOptions).then( handleResponse);
  return id ? fetch(searchParams, requestOptions).then(handleResponse).catch(function (error) {
    console.error('getById error:', error);
    return Promise.reject(new Error("'id not defined"));
  }) : Promise.reject(new Error("'id not defined"));
}
function register(user, api) {
  var requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(user)
  };
  return fetch("".concat(api.apiUrl, "/users/register"), requestOptions).then(handleResponse);
}
function update(user, api) {
  var requestOptions = {
    method: 'PUT',
    headers: _objectSpread(_objectSpread({}, authHeader()), {}, {
      'Content-Type': 'application/json'
    }),
    body: JSON.stringify(user)
  };
  return fetch("".concat(api.apiUrl, "/users/").concat(user.id), requestOptions).then(handleResponse);
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id, api) {
  var requestOptions = {
    method: 'DELETE',
    headers: authHeader()
  };
  return fetch("".concat(api.apiUrl, "/users/").concat(id), requestOptions).then(handleResponse);
}
var userService = {
  login: login,
  logout: logout,
  register: register,
  getAll: getAll,
  getById: getById,
  update: update,
  delete: _delete
};
var _default = userService;
export default _default;
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(_extends, "_extends", "/usr/share/vista/model-site/src/services/user.service.js");
  reactHotLoader.register(logout, "logout", "/usr/share/vista/model-site/src/services/user.service.js");
  reactHotLoader.register(handleResponse, "handleResponse", "/usr/share/vista/model-site/src/services/user.service.js");
  reactHotLoader.register(login, "login", "/usr/share/vista/model-site/src/services/user.service.js");
  reactHotLoader.register(getAll, "getAll", "/usr/share/vista/model-site/src/services/user.service.js");
  reactHotLoader.register(getById, "getById", "/usr/share/vista/model-site/src/services/user.service.js");
  reactHotLoader.register(register, "register", "/usr/share/vista/model-site/src/services/user.service.js");
  reactHotLoader.register(update, "update", "/usr/share/vista/model-site/src/services/user.service.js");
  reactHotLoader.register(_delete, "_delete", "/usr/share/vista/model-site/src/services/user.service.js");
  reactHotLoader.register(userService, "userService", "/usr/share/vista/model-site/src/services/user.service.js");
  reactHotLoader.register(_default, "default", "/usr/share/vista/model-site/src/services/user.service.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();