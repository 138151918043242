(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import createRootReducer from '../reducers';
var _default = function _default(history) {
  var initialState = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var middlewares = [routerMiddleware(history), thunk
  // Add other middlewares here
  ];
  // Use Redux DevTools Extension in development
  var composeEnhancers = __DEV__ && typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  var enhancers = composeEnhancers(applyMiddleware.apply(void 0, middlewares)
  // Add other enhancers here
  );
  var store = createStore(createRootReducer(history), initialState, enhancers);
  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../reducers', function () {
      try {
        var createNextReducer = require('../reducers').default;
        store.replaceReducer(createNextReducer(history));
      } catch (error) {
        console.error("==> \uD83D\uDE2D  Reducer hot reloading error ".concat(error));
      }
    });
  }
  return store;
};
export default _default;
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(_default, "default", "/usr/share/vista/model-site/src/utils/configureStore.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();