(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.regexp.to-string.js";
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
/*
 * MODIFIED
 *    $Date: $
 */
/* eslint-disable no-restricted-syntax */
var isProduction = process.env.NODE_ENV === 'production';
var prefix = 'Invariant failed';

// Want to use this:
// export class RbdInvariant extends Error { }
// But it causes babel to bring in a lot of code

export function RbdInvariant(message) {
  this.message = message;
}
// $FlowFixMe
RbdInvariant.prototype.toString = function toString() {
  return this.message;
};

// A copy-paste of tiny-invariant but with a custom error type
// Throw an error if the condition fails
export function invariant(condition, message) {
  if (condition) {
    return;
  }
  if (isProduction) {
    // In production we strip the message but still throw
    throw new RbdInvariant(prefix);
  } else {
    // When not in production we allow the message to pass through
    // *This block will be removed in production builds*
    throw new RbdInvariant("".concat(prefix, ": ").concat(message || ''));
  }
}
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(isProduction, "isProduction", "/usr/share/vista/model-site/src/components/MultiDragQueueTable/invariant.js");
  reactHotLoader.register(prefix, "prefix", "/usr/share/vista/model-site/src/components/MultiDragQueueTable/invariant.js");
  reactHotLoader.register(RbdInvariant, "RbdInvariant", "/usr/share/vista/model-site/src/components/MultiDragQueueTable/invariant.js");
  reactHotLoader.register(invariant, "invariant", "/usr/share/vista/model-site/src/components/MultiDragQueueTable/invariant.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();