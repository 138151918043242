(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
// import { createBrowserHistory } from 'history';

var _history = function _history() {
  if (typeof document !== 'undefined') {
    // are we rendering from the server?
    var _require = require('history'),
      createBrowserHistory = _require.createBrowserHistory; //  .default;

    return createBrowserHistory();
  }
  return null;
};
var history = _history();
var _default = history;
export default _default;
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(_history, "_history", "/usr/share/vista/model-site/src/services/history.js");
  reactHotLoader.register(history, "history", "/usr/share/vista/model-site/src/services/history.js");
  reactHotLoader.register(_default, "default", "/usr/share/vista/model-site/src/services/history.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();