function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
import _assign from "lodash/assign";
(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import "core-js/modules/es6.regexp.constructor.js";
import "core-js/modules/es6.array.find-index.js";
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.string.iterator.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.array.iterator.js";
import "core-js/modules/web.dom.iterable.js";
import "core-js/modules/es6.array.from.js";
import "core-js/modules/es6.array.slice.js";
import "core-js/modules/es6.function.name.js";
import "core-js/modules/es6.number.constructor.js";
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
/*
 * MODIFIED
 *    $Date: Tue Dec 17 14:00:56 PST 2019$
 */
var initialState = {
  readyStatus: ['RECORDS_INVALID'],
  // status of each slot loading
  err: null,
  list: [],
  // latest slot listing (a new list name is assigned to each recorset)
  experimentId: null,
  timing: {} // latest loading time
};
var _default = function _default() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  console.log('REDUCER_STATE', state, action);
  var prefix = action.prefix;
  var key = prefix && prefix.length ? "list".concat(prefix) : 'dummy';

  // replace or add the new readyStatus into the state variable
  var pattern = new RegExp("^".concat(prefix, "RECORDS"));
  var ind = state.readyStatus.findIndex(function (p) {
    return pattern.test(p);
  });
  var newReadyStatus = ind >= 0 ? state.readyStatus.map(function (e) {
    return pattern.test(e) ? action.type : e;
  }) : [].concat(_toConsumableArray(state.readyStatus), [action.type]);
  var newTiming = _assign({}, state.timing, _defineProperty({}, key, action.time ? action.time : new Date().getTime()));
  console.log('REDUCER_NEW READY STATUS', newReadyStatus);
  console.log('REDUCER_NEW TIMING STATUS', newTiming);
  switch (action.type) {
    case "".concat(prefix, "RECORDS_REQUESTING"):
    case "".concat(prefix, "RECORDS_NEEDUPDATE"):
      console.log(action.type);
      return _assign({}, state, {
        timing: newTiming,
        readyStatus: newReadyStatus
      });
    case "".concat(prefix, "RECORDS_FAILURE"):
      console.log(action.type);
      return _assign({}, state, {
        readyStatus: newReadyStatus,
        timing: newTiming,
        err: action.err
      });
    case "".concat(prefix, "RECORDS_SUCCESS"):
      console.log(action.type);
      return _assign({}, state, _defineProperty(_defineProperty({
        readyStatus: newReadyStatus,
        list: action.data,
        timing: newTiming
      }, key, action.data), "experimentId", action.data.length ? action.data[0].experimentId : null));
    default:
      return state;
  }
};
export default _default;
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(initialState, "initialState", "/usr/share/vista/model-site/src/reducers/data_records.js");
  reactHotLoader.register(_default, "default", "/usr/share/vista/model-site/src/reducers/data_records.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();