(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
/*
 * DESCRIPTION
 *    from https://medium.com/@Charles_Stover/how-to-convert-withrouter-to-a-react-hook-19bb02a29ed6
 *
 * MODIFIED
 *    $Date: $
 */

import { useContext, useEffect } from 'react';
import { __RouterContext } from 'react-router';
import useForceUpdate from 'use-force-update';
var useReactRouter = function useReactRouter() {
  var forceUpdate = useForceUpdate();
  var routerContext = useContext(__RouterContext);
  useEffect(function () {
    return (
      //  subscribe function
      routerContext.history.listen(forceUpdate)
    );
  },
  // return unsubscribe()
  [routerContext /* memoization parameters here */]);
  return routerContext;
};
__signature__(useReactRouter, "useForceUpdate{forceUpdate}\nuseContext{routerContext}\nuseEffect{}", function () {
  return [useForceUpdate];
});
var _default = useReactRouter;
export default _default;
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(useReactRouter, "useReactRouter", "/usr/share/vista/model-site/src/components/useReactRouter/index.js");
  reactHotLoader.register(_default, "default", "/usr/share/vista/model-site/src/components/useReactRouter/index.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();