(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
// import { Action } from '../types';

function success(message) {
  return {
    type: 'ALERT_SUCCESS',
    message: message
  };
}
function error(message) {
  return {
    type: 'ALERT_ERROR',
    message: message
  };
}
function clear() {
  return {
    type: 'ALERT_CLEAR'
  };
}
var alertActions = {
  success: success,
  error: error,
  clear: clear
};
var _default = alertActions;
export default _default;
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(success, "success", "/usr/share/vista/model-site/src/actions/alert.actions.js");
  reactHotLoader.register(error, "error", "/usr/share/vista/model-site/src/actions/alert.actions.js");
  reactHotLoader.register(clear, "clear", "/usr/share/vista/model-site/src/actions/alert.actions.js");
  reactHotLoader.register(alertActions, "alertActions", "/usr/share/vista/model-site/src/actions/alert.actions.js");
  reactHotLoader.register(_default, "default", "/usr/share/vista/model-site/src/actions/alert.actions.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();