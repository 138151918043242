(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
/*
 * MODIFIED
 *    $Date: Tue Feb 22 00:44:41 PST 2022$
 */import { withCookies } from 'react-cookie';
// import { /* usersAction , apiCoordAction */ } from './actions';
import App from './app';
import { asyncHome, /* asyncUserInfo, */addExperiment, viewExperiments, viewInjections, NotFound } from './pages';
import { Login, PrivateRoute } from './components';
var _default = [{
  component: withCookies(App),
  routes: [{
    path: '/login',
    exact: true,
    component: Login
  }, {
    path: '/',
    exact: true,
    Router: PrivateRoute,
    component: asyncHome // Add your route here
  },
  /*
  {
    path: '/',
    exact: true,
    component: externalHome
  }, 
  {
    path: '/internal',
    exact: true,
    Router: PrivateRoute,
    component: asyncHome // Add your route here
  },
  */
  /*
  {
    path: '/UserInfo/:id',
    component: asyncUserInfo,
    loadData: ({ params }: Object) => [
      userAction.fetchUserIfNeeded(params.id)
    ]
  },
  */
  {
    path: '/addExperiment/:step',
    component: addExperiment,
    Router: PrivateRoute
    /* Router: PrivateRoute,
    roles: ['admin', 'bureaucrat'],
    component: addExperiment,
    loadData: ({ params }: Object) => [
      userAction.fetchUserIfNeeded(params.id)
    ] */
  }, {
    path: '/viewExperiments',
    Router: PrivateRoute,
    component: viewExperiments
    /* Router: PrivateRoute,
    roles: ['admin', 'bureaucrat'],
    component: viewExperiments,
    loadData: ({ params }: Object) => [
      userAction.fetchUserIfNeeded(params.id)
    ] */
  }, {
    path: '/viewInjections',
    Router: PrivateRoute,
    component: viewInjections
  }, {
    component: NotFound
  }]
}];
export default _default;
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(_default, "default", "/usr/share/vista/model-site/src/routes.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();