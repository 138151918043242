(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
if (typeof window === 'undefined' || !window) {
  require('localstorage-polyfill'); // placeholder for localStorage
}
var user = JSON.parse(localStorage.getItem('user'));
var initialState = user ? {
  loggedIn: true,
  user: user,
  readyStatus: 'AUTH_FIRST'
} : {
  readyStatus: 'AUTH_INVALID'
};
export function authentication() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  console.log('AUTHENTICATION action', action);
  switch (action.type) {
    case 'AUTH_LOGIN_REQUEST':
      return {
        loggingIn: true,
        user: action.user,
        readyStatus: 'AUTH_REQUESTING'
      };
    case 'AUTH_LOGIN_SUCCESS':
      return {
        loggedIn: true,
        user: action.user,
        readyStatus: 'AUTH_SUCCESS'
      };
    case 'AUTH_LOGIN_FAILURE':
      return {
        readyStatus: 'AUTH_FAILURE'
      };
    case 'AUTH_LOGOUT':
      return {
        readyStatus: 'AUTH_LOGOUT'
      };
    case 'AUTH_GETUSER_REQUEST':
      return {
        loggingIn: true,
        user: action.user,
        readyStatus: 'AUTH_GETUSER_REQUESTING'
      };
    case 'AUTH_GETUSER_SUCCESS':
      return {
        loggedIn: true,
        user: action.user,
        readyStatus: 'AUTH_GETUSER_SUCCESS'
      };
    case 'AUTH_GETUSER_FAILURE':
      return {
        readyStatus: 'AUTH_GETUSER_FAILURE'
      };
    default:
      return state;
  }
}
var usersInitialState = {
  readyStatus: 'GETALL_INVALID'
};
export function usersGeneral() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : usersInitialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case 'NONAUTH_GETALL_REQUEST':
      console.log('NONAUTH_GETALL_REQUEST');
      return {
        users: action.users,
        readyStatus: 'NONAUTH_GETALL_REQUESTING'
      };
    case 'NONAUTH_GETALL_SUCCESS':
      console.log('NONAUTH_GETALL_SUCCESS');
      return {
        users: action.users,
        readyStatus: 'NONAUTH_GETALL_SUCCESS'
      };
    case 'NONAUTH_GETALL_FAILURE':
      console.log('NONAUTH_GETALL_FAILURE');
      return {
        readyStatus: 'NONAUTH_GETALL_FAILURE'
      };
    default:
      return state;
  }
}
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(user, "user", "/usr/share/vista/model-site/src/reducers/authentication.reducer.js");
  reactHotLoader.register(initialState, "initialState", "/usr/share/vista/model-site/src/reducers/authentication.reducer.js");
  reactHotLoader.register(authentication, "authentication", "/usr/share/vista/model-site/src/reducers/authentication.reducer.js");
  reactHotLoader.register(usersInitialState, "usersInitialState", "/usr/share/vista/model-site/src/reducers/authentication.reducer.js");
  reactHotLoader.register(usersGeneral, "usersGeneral", "/usr/share/vista/model-site/src/reducers/authentication.reducer.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();