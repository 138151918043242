(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
import PropTypes from 'prop-types';
export var stylePropType = PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string]));
export var dataPropType = PropTypes.arrayOf(PropTypes.shape({
  title: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  value: PropTypes.number.isRequired,
  color: PropTypes.string,
  key: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  style: stylePropType
}));
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(stylePropType, "stylePropType", "/usr/share/vista/model-site/src/components/PieChart/propTypes.js");
  reactHotLoader.register(dataPropType, "dataPropType", "/usr/share/vista/model-site/src/components/PieChart/propTypes.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();