import _assign from "lodash/assign";
(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
var initialState = {
  readyStatus: 'SYS_SAVE_INVALID',
  err: null,
  list: []
};
var _default = function _default() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case 'SYS_SAVE_REQUESTING':
      console.log('SYS_SAVE_REQUESTING');
      return _assign({}, state, {
        readyStatus: 'SYS_SAVE_REQUESTING'
      });
    case 'SYS_SAVE_FAILURE':
      console.log('SYS_SAVE_FAILURE');
      return _assign({}, state, {
        readyStatus: 'SYS_SAVE_FAILURE',
        err: action.err
      });
    case 'SYS_SAVE_SUCCESS':
      console.log('SYS_SAVE_SUCCESS');
      return _assign({}, state, {
        readyStatus: 'SYS_SAVE_SUCCESS',
        list: action.data,
        err: null
      });
    default:
      return state;
  }
};
export default _default;
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(initialState, "initialState", "/usr/share/vista/model-site/src/reducers/sysinfo.reducer.js");
  reactHotLoader.register(_default, "default", "/usr/share/vista/model-site/src/reducers/sysinfo.reducer.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();