(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
var authHeader = function authHeader(token) {
  // return authorization header with jwt token
  var user = JSON.parse(localStorage.getItem('user'));
  if (user && user.token && token) {
    return {
      Authorization: "Bearer ".concat(user.token)
    };
  }
  return {};
};
var _default = authHeader;
export default _default;
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(authHeader, "authHeader", "/usr/share/vista/model-site/src/services/auth-header.js");
  reactHotLoader.register(_default, "default", "/usr/share/vista/model-site/src/services/auth-header.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();