import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.string.iterator.js";
import "core-js/modules/es6.array.iterator.js";
import "core-js/modules/web.dom.iterable.js";
import "core-js/modules/es6.array.from.js";
import "core-js/modules/es6.array.slice.js";
import "core-js/modules/es6.function.name.js";
import _isNil from "lodash/isNil";
(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.regexp.to-string.js";
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
/* eslint no-nested-ternary: warn */
/*
 * MODIFIED
 *    $Date: Tue Feb 11 23:00:30 IST 2020$
 */
export var Numbers15 = [{
  label: 'NI',
  value: '-9999'
}, {
  label: '1',
  value: '1'
}, {
  label: '2',
  value: '2'
}, {
  label: '3',
  value: '3'
}, {
  label: '4',
  value: '4'
}, {
  label: '5',
  value: '5'
}];
export var LETTERS = _toConsumableArray('ABCDEFGHIJKLMNOPQRSTUVWXYZ').map(function (c) {
  return {
    label: c,
    value: c
  };
});
export var boolTF = [{
  label: 'yes',
  value: 'true'
}, {
  label: 'no',
  value: 'false'
}];
export var genotype = [
//{ label: 'not tested', value: 'no.test' },
{
  label: 'tandem',
  value: 'tandem'
}, {
  label: 'single',
  value: 'single'
}, {
  label: 'random',
  value: 'random'
}
//{ label: 'negative', value: 'negative' }
];

// jq '.["ucscGenomes"] | keys' ../tools/ucscGenomes.json
export var assemblies = [{
  label: 'ancCey1',
  value: 'ancCey1'
}, {
  label: 'anoGam3',
  value: 'anoGam3'
}, {
  label: 'apiMel4',
  value: 'apiMel4'
}, {
  label: 'oryAfe1',
  value: 'oryAfe1'
}, {
  label: 'pygAde1',
  value: 'pygAde1'
}, {
  label: 'xenLae2',
  value: 'xenLae2'
}, {
  label: 'vicPac2',
  value: 'vicPac2'
}, {
  label: 'allMis1',
  value: 'allMis1'
}, {
  label: 'corBra1',
  value: 'corBra1'
}, {
  label: 'phoRub1',
  value: 'phoRub1'
}, {
  label: 'colAng1',
  value: 'colAng1'
}, {
  label: 'calAnn1',
  value: 'calAnn1'
}, {
  label: 'letCam1',
  value: 'letCam1'
}, {
  label: 'dasNov3',
  value: 'dasNov3'
}, {
  label: 'gadMor1',
  value: 'gadMor1'
}, {
  label: 'papAnu4',
  value: 'papAnu4'
}, {
  label: 'camFer1',
  value: 'camFer1'
}, {
  label: 'halLeu1',
  value: 'halLeu1'
}, {
  label: 'apaVit1',
  value: 'apaVit1'
}, {
  label: 'haeCon2',
  value: 'haeCon2'
}, {
  label: 'tytAlb1',
  value: 'tytAlb1'
}, {
  label: 'eptFus1',
  value: 'eptFus1'
}, {
  label: 'bisBis1',
  value: 'bisBis1'
}, {
  label: 'pteAle1',
  value: 'pteAle1'
}, {
  label: 'eulMac1',
  value: 'eulMac1'
}, {
  label: 'rhiBie1',
  value: 'rhiBie1'
}, {
  label: 'panPan2',
  value: 'panPan2'
}, {
  label: 'aptMan1',
  value: 'aptMan1'
}, {
  label: 'mesUni1',
  value: 'mesUni1'
}, {
  label: 'octDeg1',
  value: 'octDeg1'
}, {
  label: 'melUnd1',
  value: 'melUnd1'
}, {
  label: 'pytBiv1',
  value: 'pytBiv1'
}, {
  label: 'hapBur1',
  value: 'hapBur1'
}, {
  label: 'otoGar3',
  value: 'otoGar3'
}, {
  label: 'caeAng2',
  value: 'caeAng2'
}, {
  label: 'caePb3',
  value: 'caePb3'
}, {
  label: 'cb4',
  value: 'cb4'
}, {
  label: 'ce11',
  value: 'ce11'
}, {
  label: 'ci3',
  value: 'ci3'
}, {
  label: 'caeJap4',
  value: 'caeJap4'
}, {
  label: 'caeRem4',
  value: 'caeRem4'
}, {
  label: 'caeSp51',
  value: 'caeSp51'
}, {
  label: 'caeSp111',
  value: 'caeSp111'
}, {
  label: 'serCan1',
  value: 'serCan1'
}, {
  label: 'eleEdw1',
  value: 'eleEdw1'
}, {
  label: 'chrAsi1',
  value: 'chrAsi1'
}, {
  label: 'felCat9',
  value: 'felCat9'
}, {
  label: 'galGal6',
  value: 'galGal6'
}, {
  label: 'panTro6',
  value: 'panTro6'
}, {
  label: 'chiLan1',
  value: 'chiLan1'
}, {
  label: 'allSin1',
  value: 'allSin1'
}, {
  label: 'criGriChoV2',
  value: 'criGriChoV2'
}, {
  label: 'manPen1',
  value: 'manPen1'
}, {
  label: 'pelSin1',
  value: 'pelSin1'
}, {
  label: 'tupChi1',
  value: 'tupChi1'
}, {
  label: 'capCar1',
  value: 'capCar1'
}, {
  label: 'latCha1',
  value: 'latCha1'
}, {
  label: 'ficAlb2',
  value: 'ficAlb2'
}, {
  label: 'cucCan1',
  value: 'cucCan1'
}, {
  label: 'proCoq1',
  value: 'proCoq1'
}, {
  label: 'bosTau9',
  value: 'bosTau9'
}, {
  label: 'macFas5',
  value: 'macFas5'
}, {
  label: 'nipNip1',
  value: 'nipNip1'
}, {
  label: 'balPav1',
  value: 'balPav1'
}, {
  label: 'lepDis1',
  value: 'lepDis1'
}, {
  label: 'droAlb1',
  value: 'droAlb1'
}, {
  label: 'droAna3',
  value: 'droAna3'
}, {
  label: 'droBia2',
  value: 'droBia2'
}, {
  label: 'droBip2',
  value: 'droBip2'
}, {
  label: 'droEle2',
  value: 'droEle2'
}, {
  label: 'droEre2',
  value: 'droEre2'
}, {
  label: 'droEug2',
  value: 'droEug2'
}, {
  label: 'droFic2',
  value: 'droFic2'
}, {
  label: 'droGri2',
  value: 'droGri2'
}, {
  label: 'droKik2',
  value: 'droKik2'
}, {
  label: 'dm6',
  value: 'dm6'
}, {
  label: 'droMir2',
  value: 'droMir2'
}, {
  label: 'droMoj3',
  value: 'droMoj3'
}, {
  label: 'droPer1',
  value: 'droPer1'
}, {
  label: 'droPse3',
  value: 'droPse3'
}, {
  label: 'droRho2',
  value: 'droRho2'
}, {
  label: 'droSec1',
  value: 'droSec1'
}, {
  label: 'droSim2',
  value: 'droSim2'
}, {
  label: 'droSuz1',
  value: 'droSuz1'
}, {
  label: 'droTak2',
  value: 'droTak2'
}, {
  label: 'droVir3',
  value: 'droVir3'
}, {
  label: 'droWil2',
  value: 'droWil2'
}, {
  label: 'droYak3',
  value: 'droYak3'
}, {
  label: 'pelCri1',
  value: 'pelCri1'
}, {
  label: 'myoDav1',
  value: 'myoDav1'
}, {
  label: 'canFam3',
  value: 'canFam3'
}, {
  label: 'dirImm1',
  value: 'dirImm1'
}, {
  label: 'NIST Tur_tru v1',
  value: 'NIST Tur_tru v1'
}, {
  label: 'capHir1',
  value: 'capHir1'
}, {
  label: 'picPub1',
  value: 'picPub1'
}, {
  label: 'manLeu1',
  value: 'manLeu1'
}, {
  label: 'eboVir3',
  value: 'eboVir3'
}, {
  label: 'loxAfr3',
  value: 'loxAfr3'
}, {
  label: 'calMil1',
  value: 'calMil1'
}, {
  label: 'aptFor1',
  value: 'aptFor1'
}, {
  label: 'loaLoa1',
  value: 'loaLoa1'
}, {
  label: 'musFur1',
  value: 'musFur1'
}, {
  label: 'bruMal2',
  value: 'bruMal2'
}, {
  label: 'fr3',
  value: 'fr3'
}, {
  label: 'thaSir1',
  value: 'thaSir1'
}, {
  label: 'nomLeu3',
  value: 'nomLeu3'
}, {
  label: 'aquChr2',
  value: 'aquChr2'
}, {
  label: 'mesAur1',
  value: 'mesAur1'
}, {
  label: 'rhiRox1',
  value: 'rhiRox1'
}, {
  label: 'gorGor5',
  value: 'gorGor5'
}, {
  label: 'phaCar1',
  value: 'phaCar1'
}, {
  label: 'chlSab2',
  value: 'chlSab2'
}, {
  label: 'cheMyd1',
  value: 'cheMyd1'
}, {
  label: 'cavPor3',
  value: 'cavPor3'
}, {
  label: 'hetBac1',
  value: 'hetBac1'
}, {
  label: 'eriEur2',
  value: 'eriEur2'
}, {
  label: 'opiHoa1',
  value: 'opiHoa1'
}, {
  label: 'corCor1',
  value: 'corCor1'
}, {
  label: 'equCab2',
  value: 'equCab2'
}, {
  label: 'chlUnd1',
  value: 'chlUnd1'
}, {
  label: 'hg38',
  value: 'hg38'
}, {
  label: 'angJap1',
  value: 'angJap1'
}, {
  label: 'cotJap2',
  value: 'cotJap2'
}, {
  label: 'dipOrd1',
  value: 'dipOrd1'
}, {
  label: 'chaVoc2',
  value: 'chaVoc2'
}, {
  label: 'orcOrc1',
  value: 'orcOrc1'
}, {
  label: 'petMar3',
  value: 'petMar3'
}, {
  label: 'braFlo2',
  value: 'braFlo2'
}, {
  label: 'jacJac1',
  value: 'jacJac1'
}, {
  label: 'egrGar1',
  value: 'egrGar1'
}, {
  label: 'anoCar2',
  value: 'anoCar2'
}, {
  label: 'musDom2',
  value: 'musDom2'
}, {
  label: 'melHap1',
  value: 'melHap1'
}, {
  label: 'melInc2',
  value: 'melInc2'
}, {
  label: 'aotNan1',
  value: 'aotNan1'
}, {
  label: 'galVar1',
  value: 'galVar1'
}, {
  label: 'anaPla1',
  value: 'anaPla1'
}, {
  label: 'triMan1',
  value: 'triMan1'
}, {
  label: 'calJac3',
  value: 'calJac3'
}, {
  label: 'oryLat1',
  value: 'oryLat1'
}, {
  label: 'geoFor1',
  value: 'geoFor1'
}, {
  label: 'pteVam1',
  value: 'pteVam1'
}, {
  label: 'astMex1',
  value: 'astMex1'
}, {
  label: 'myoLuc2',
  value: 'myoLuc2'
}, {
  label: 'panRed1',
  value: 'panRed1'
}, {
  label: 'balAcu1',
  value: 'balAcu1'
}, {
  label: 'mm10',
  value: 'mm10'
}, {
  label: 'micMur3',
  value: 'micMur3'
}, {
  label: 'necAme1',
  value: 'necAme1'
}, {
  label: 'hetGla2',
  value: 'hetGla2'
}, {
  label: 'oreNil3',
  value: 'oreNil3'
}, {
  label: 'merNub1',
  value: 'merNub1'
}, {
  label: 'fulGla1',
  value: 'fulGla1'
}, {
  label: 'oncVol1',
  value: 'oncVol1'
}, {
  label: 'monDom5',
  value: 'monDom5'
}, {
  label: 'ponAbe3',
  value: 'ponAbe3'
}, {
  label: 'strCam1',
  value: 'strCam1'
}, {
  label: 'priExs1',
  value: 'priExs1'
}, {
  label: 'priPac3',
  value: 'priPac3'
}, {
  label: 'odoRosDiv1',
  value: 'odoRosDiv1'
}, {
  label: 'chrPic2',
  value: 'chrPic2'
}, {
  label: 'ailMel1',
  value: 'ailMel1'
}, {
  label: 'amaVit1',
  value: 'amaVit1'
}, {
  label: 'falPer1',
  value: 'falPer1'
}, {
  label: 'susScr11',
  value: 'susScr11'
}, {
  label: 'ascSuu1',
  value: 'ascSuu1'
}, {
  label: 'macNem1',
  value: 'macNem1'
}, {
  label: 'ochPri3',
  value: 'ochPri3'
}, {
  label: 'burXyl1',
  value: 'burXyl1'
}, {
  label: 'mOrnAna1.pri.v4',
  value: 'mOrnAna1.pri.v4'
}, {
  label: 'micOch1',
  value: 'micOch1'
}, {
  label: 'neoBri1',
  value: 'neoBri1'
}, {
  label: 'nasLar1',
  value: 'nasLar1'
}, {
  label: 'punNye1',
  value: 'punNye1'
}, {
  label: 'oryCun2',
  value: 'oryCun2'
}, {
  label: 'rn6',
  value: 'rn6'
}, {
  label: 'tauEry1',
  value: 'tauEry1'
}, {
  label: 'carCri1',
  value: 'carCri1'
}, {
  label: 'gavSte1',
  value: 'gavSte1'
}, {
  label: 'rheMac10',
  value: 'rheMac10'
}, {
  label: 'bucRhi1',
  value: 'bucRhi1'
}, {
  label: 'acaChl1',
  value: 'acaChl1'
}, {
  label: 'proCap1',
  value: 'proCap1'
}, {
  label: 'colLiv1',
  value: 'colLiv1'
}, {
  label: 'sacCer3',
  value: 'sacCer3'
}, {
  label: 'strPur2',
  value: 'strPur2'
}, {
  label: 'falChe1',
  value: 'falChe1'
}, {
  label: 'araMac1',
  value: 'araMac1'
}, {
  label: 'eulFla1',
  value: 'eulFla1'
}, {
  label: 'aplCal1',
  value: 'aplCal1'
}, {
  label: 'oviAri4',
  value: 'oviAri4'
}, {
  label: 'sorAra2',
  value: 'sorAra2'
}, {
  label: 'choHof1',
  value: 'choHof1'
}, {
  label: 'cerAty1',
  value: 'cerAty1'
}, {
  label: 'xipMac1',
  value: 'xipMac1'
}, {
  label: 'colStr1',
  value: 'colStr1'
}, {
  label: 'apaSpi1',
  value: 'apaSpi1'
}, {
  label: 'lepOcu1',
  value: 'lepOcu1'
}, {
  label: 'speTri2',
  value: 'speTri2'
}, {
  label: 'saiBol1',
  value: 'saiBol1'
}, {
  label: 'conCri1',
  value: 'conCri1'
}, {
  label: 'gasAcu1',
  value: 'gasAcu1'
}, {
  label: 'eurHel1',
  value: 'eurHel1'
}, {
  label: 'triCas2',
  value: 'triCas2'
}, {
  label: 'tarSyr2',
  value: 'tarSyr2'
}, {
  label: 'sarHar1',
  value: 'sarHar1'
}, {
  label: 'echTel2',
  value: 'echTel2'
}, {
  label: 'tetNig2',
  value: 'tetNig2'
}, {
  label: 'strRat2',
  value: 'strRat2'
}, {
  label: 'panHod1',
  value: 'panHod1'
}, {
  label: 'nanPar1',
  value: 'nanPar1'
}, {
  label: 'pseHum1',
  value: 'pseHum1'
}, {
  label: 'tupBel1',
  value: 'tupBel1'
}, {
  label: 'triSpi1',
  value: 'triSpi1'
}, {
  label: 'melGal5',
  value: 'melGal5'
}, {
  label: 'macEug2',
  value: 'macEug2'
}, {
  label: 'lepWed1',
  value: 'lepWed1'
}, {
  label: 'triSui1',
  value: 'triSui1'
}, {
  label: 'cerSim1',
  value: 'cerSim1'
}, {
  label: 'tinGut2',
  value: 'tinGut2'
}, {
  label: 'cebCap1',
  value: 'cebCap1'
}, {
  label: 'halAlb1',
  value: 'halAlb1'
}, {
  label: 'phaLep1',
  value: 'phaLep1'
}, {
  label: 'zonAlb1',
  value: 'zonAlb1'
}, {
  label: 'xenTro9',
  value: 'xenTro9'
}, {
  label: 'pteGut1',
  value: 'pteGut1'
}, {
  label: 'takFla1',
  value: 'takFla1'
}, {
  label: 'taeGut2',
  value: 'taeGut2'
}, {
  label: 'mayZeb1',
  value: 'mayZeb1'
}, {
  label: 'danRer11',
  value: 'danRer11'
}, {
  label: 'MacSob_v1_BIUU',
  value: 'MacSob_v1_BIUU'
}, {
  label: 'OphHan1.0',
  value: 'OphHan1.0'
}, {
  label: 'Python_molurus_bivittatus-5.0.2',
  value: 'Python_molurus_bivittatus-5.0.2'
}, {
  label: 'crotalus_mitchellii_pyrrhus',
  value: 'crotalus_mitchellii_pyrrhus'
}, {
  label: 'boa',
  value: 'boa'
}, {
  label: 'MPIMG_talOcc4',
  value: 'MPIMG_talOcc4'
}, {
  label: 'LER_WGS_1',
  value: 'LER_WGS_1'
}, {
  label: 'thyCyn2',
  value: 'thyCyn2'
}, {
  label: 'phaCin_tgac_v2',
  value: 'phaCin_tgac_v2'
}];
export var parseBool = function parseBool(val) {
  var TF = /^(true|false|yes|no|y|n|T|F)$/i;
  return typeof val === 'boolean' || TF.test(val);
};
export var parseGenotype = function parseGenotype(val) {
  var classes = /^(tandem|single|random)$/i;
  return classes.test(val);
};

/* 
loose coord parser - ideally chr form would apply on mm10/hg38
and loose [0-9A-Za-z] on everything else
*/
export var parseUCSCcoords = function parseUCSCcoords(val) {
  // const coord = /^chr[0-9MXY]+:[0-9]+-[0-9]+$/i;
  var coord = /^[.A-Za-z0-9]+:[0-9]+-[0-9]+$/i;
  return coord.test(val);
};
export var parseATGC = function parseATGC(val) {
  var ATGCN = /^[ATGCN]+$/i;
  return ATGCN.test(val);
};
export var parseString = function parseString(val) {
  var str_ = /^[a-z0-9.,()=_+-:;<>?!@#$%^&* /]+$/i;
  return str_.test(val);
};
export var parseChar = function parseChar(val) {
  var chr_ = /^[a-z]$/i;
  return chr_.test(val);
};
export var parseStaging = function parseStaging(val) {
  var stage = /^((cs|e)[0-9.]+|blasts|term)$/i;
  return stage.test(val);
};
export var parseDate = function parseDate(val) {
  var dt_ = /^[0-9]{4}-[0-9]{2}-[0-9]{2}(T[0-9:.]+)?Z?$/i;
  return dt_.test(val);
};
export var parseInteger = function parseInteger(val) {
  return typeof val === 'number' || /^(NI|[0-9-]+)$/.test(val);
};
export var parseNumber = function parseNumber(val) {
  return typeof val === 'number' || /^(NI|[0-9.-]+)$/.test(val);
};

// compare two values. If they are of different types, convert both
// to string and then compare.
export var neq = function neq(val1, val2) {
  if (typeof val1 === 'undefined') return false;
  if (typeof val2 === 'undefined') return false;
  if (_typeof(val1) !== _typeof(val2)) return val1.toString() !== val2.toString();
  return val1 !== val2;
};

// ----------------------------------------------------------------------
export var parseCell = function parseCell(value, column) {
  var ans = null;
  var TF = {
    yes: 'yes',
    no: 'no',
    y: 'yes',
    n: 'no',
    true: 'yes',
    TRUE: 'yes',
    false: 'no',
    FALSE: 'no',
    T: 'yes',
    F: 'no',
    null: 'no'
  };
  switch (column.format) {
    case 'Bool':
      ans = TF["".concat(value)] || null;
      break;
    case 'Date':
      if (_isNil(value) || value === '0000-01-01T00:00:00.000Z') ans = 'no date';else ans = new Date(value).toLocaleDateString();
      break;
    case 'Integer':
      ans = value === -9999 || parseInt(value, 10) === -9999 ? 'NI' : value === undefined ? null : value;
      break;
    default:
      ans = value || '';
  }
  return ans;
};
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(Numbers15, "Numbers15", "/usr/share/vista/model-site/src/utils/formatParsers.js");
  reactHotLoader.register(LETTERS, "LETTERS", "/usr/share/vista/model-site/src/utils/formatParsers.js");
  reactHotLoader.register(boolTF, "boolTF", "/usr/share/vista/model-site/src/utils/formatParsers.js");
  reactHotLoader.register(genotype, "genotype", "/usr/share/vista/model-site/src/utils/formatParsers.js");
  reactHotLoader.register(assemblies, "assemblies", "/usr/share/vista/model-site/src/utils/formatParsers.js");
  reactHotLoader.register(parseBool, "parseBool", "/usr/share/vista/model-site/src/utils/formatParsers.js");
  reactHotLoader.register(parseGenotype, "parseGenotype", "/usr/share/vista/model-site/src/utils/formatParsers.js");
  reactHotLoader.register(parseUCSCcoords, "parseUCSCcoords", "/usr/share/vista/model-site/src/utils/formatParsers.js");
  reactHotLoader.register(parseATGC, "parseATGC", "/usr/share/vista/model-site/src/utils/formatParsers.js");
  reactHotLoader.register(parseString, "parseString", "/usr/share/vista/model-site/src/utils/formatParsers.js");
  reactHotLoader.register(parseChar, "parseChar", "/usr/share/vista/model-site/src/utils/formatParsers.js");
  reactHotLoader.register(parseStaging, "parseStaging", "/usr/share/vista/model-site/src/utils/formatParsers.js");
  reactHotLoader.register(parseDate, "parseDate", "/usr/share/vista/model-site/src/utils/formatParsers.js");
  reactHotLoader.register(parseInteger, "parseInteger", "/usr/share/vista/model-site/src/utils/formatParsers.js");
  reactHotLoader.register(parseNumber, "parseNumber", "/usr/share/vista/model-site/src/utils/formatParsers.js");
  reactHotLoader.register(neq, "neq", "/usr/share/vista/model-site/src/utils/formatParsers.js");
  reactHotLoader.register(parseCell, "parseCell", "/usr/share/vista/model-site/src/utils/formatParsers.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();