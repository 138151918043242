(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.keys.js";
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
/*
 * DESCRIPTION
 *    A landing sink for recurring variables. Recurring function
 *    should be placed in utils/
 *
 * MODIFIED
 *    $Date: Tue Feb 11 23:14:42 IST 2020$
 */

export var hierarchies = {
  LOCUS: 4,
  ELEMENT: 8,
  EXPERIMENT: 12,
  INJECTION: 16,
  EMBRYO: 20,
  IMAGE: 24,
  SLIDE: 28,
  SECTION: 32 // originally narrated PLANE
};

// the reverse of 'hierarchies()'
export var seihcrareih = function seihcrareih(h) {
  var H = hierarchies;
  var level = Object.keys(H).filter(function (k) {
    return H[k] === h;
  });
  return level[0];
};
export var steps = ['entry', 'queueing', 'collection', 'genotyping', 'imaging', 'curation'];
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(hierarchies, "hierarchies", "/usr/share/vista/model-site/src/app/settings/index.js");
  reactHotLoader.register(seihcrareih, "seihcrareih", "/usr/share/vista/model-site/src/app/settings/index.js");
  reactHotLoader.register(steps, "steps", "/usr/share/vista/model-site/src/app/settings/index.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();